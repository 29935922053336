import { toast } from "react-hot-toast";
import { studentEndpoints } from "../apis";
import { apiConnector } from "../apiconnector";
import { setPaymentLoading } from "../../slices/courseSlice";
import { resetCart } from "../../slices/cartSlice";

const { COURSE_PAYMENT_API, COURSE_VERIFY_API } = studentEndpoints;

export async function buyCourse(token, courses, price) {
  const toastId = toast.loading("Loading...");
  // console.log(courses, "courses in student features api ");
  // console.log(COURSE_PAYMENT_API, "COURSE_PAYMENT_API url");
  try {
    // Initiate the order
    const orderResponse = await apiConnector(
      "POST",
      COURSE_PAYMENT_API,
      { courses, price },
      {
        Authorization: `Bearer ${token}`,
      }
    );

    if (!orderResponse.data.success) {
      throw new Error(orderResponse.data.message);
    }

    console.log("PRINTING orderResponse", orderResponse);
    const paymentUrl =
      orderResponse.data.data.data.instrumentResponse.redirectInfo.url;

    // Redirect to PhonePe payment URL
    window.location.href = paymentUrl;
  } catch (error) {
    console.log("PAYMENT API ERROR.....", error);
    toast.error("Could not initiate Payment");
  }
  toast.dismiss(toastId);
}

export const checkPaymentStatus = async (
  merchantTransactionId,
  navigate,
  dispatch
) => {
  const toastId = toast.loading("Loading...");
  dispatch(setPaymentLoading(true));
  try {
    const statusResponse = await apiConnector(
      "GET",
      `${COURSE_VERIFY_API}/${merchantTransactionId}`,
      null,
      {}
    );

    // console.log("Status Response:", statusResponse);

    if (statusResponse.data.success) {
      // Payment successful, take further actions like resetting the cart, updating user data, etc.
      toast.success("Payment Successful, you are added to the course !!");
      navigate("/dashboard/enrolled-courses");
      dispatch(resetCart());
      return statusResponse.data.data.data;
    } else {
      toast.error("Payment failed or is still pending.");
      return null;
    }
  } catch (error) {
    // console.log("PAYMENT STATUS API ERROR.....", error);
    toast.error("Could not verify payment status");
  } finally {
    toast.dismiss(toastId);
    dispatch(setPaymentLoading(false));
  }
};

// async function sendPaymentSuccessEmail(response, amount, token) {
//   try {
//     await apiConnector(
//       "POST",
//       SEND_PAYMENT_SUCCESS_EMAIL_API,
//       {
//         orderId: response.orderId,
//         paymentId: response.paymentId,
//         amount,
//       },
//       {
//         Authorization: `Bearer ${token}`,
//       }
//     );
//   } catch (error) {
//     console.log("PAYMENT SUCCESS EMAIL ERROR....", error);
//   }
// }

// async function verifyPayment(bodyData, token, navigate, dispatch) {
//   const toastId = toast.loading("Verifying Payment....");
//   dispatch(setPaymentLoading(true));
//   try {
//     const response = await apiConnector("POST", COURSE_VERIFY_API, bodyData, {
//       Authorization: `Bearer ${token}`,
//     });

//     if (!response.data.success) {
//       throw new Error(response.data.message);
//     }
//     toast.success("Payment Successful, you are added to the course !!");
//     navigate("/dashboard/enrolled-courses");
//     dispatch(resetCart());
//   } catch (error) {
//     console.log("PAYMENT VERIFY ERROR....", error);
//     toast.error("Could not verify Payment");
//   }
//   toast.dismiss(toastId);
//   dispatch(setPaymentLoading(false));
// }
